import Fb from "../assets/images/Facebook.png";
import Ig from "../assets/images/Instagram.png";
import Tiktok from "../assets/images/Tik.png";
import LinkedIn from "../assets/images/Linkedin.png";

export const APP_NAME = "Logs Plug";

export const TAGS = {
  Dashboard: "Dashboard",
  Logs: "Logs",
  Add_Logs: "Sell Logs",
  View_Log: "View Log",
  Wallet: "Wallet",
  Orders: "Orders",
  View_Orders: "View Orders",
  Transactions: "Transactions",
  Settings: "Settings",
  Password: "Password"
};

export const APP_ROUTES_NAME = {
  Dashboard: "Dashboard",
  Logs_Category: "Logs Category",
  Logs: "Logs",
  Numbers: "Numbers",
  Numbers_Service_List: "Numbers Service List",
  Add_Logs: "Sell Logs",
  View_Log: "View Log",
  Wallet: "Wallet",
  Orders: "Orders",
  View_Orders: "View Orders",
  Transactions: "Transactions",
  Notifications: "Notifications",
  NotificationsStatistics: "NotificationsStatistics",
  Settings: "Settings",
  Profile: "Profile",
  Password: "Password",
  Download_Logs: "Download Logs",
  FAQ: "FAQ"
};

export const APP_ROUTES_PATH = {
  Dashboard: "/logs-dashboard",
  Logs_Category: "/logs-dashboard",
  Logs: "/logs",
  Logs_list: "/logs-list",
  Numbers: "/numbers",
  Numbers_Service_List: "/numbers/numbers-service-list/:id",
  Numbers_Rent_History:"/numbers/numbers-rent-history",
  Add_Logs: "/sell-logs",
  Wallet: "/wallet",
  // View_Log: "/products/view-products",
  Orders: "/order-history",
  View_Orders: "/orders/view-order",
  Transactions: "/transactions",
  Settings: "/settings"
};

export const STEPPERLIST = [
  { title: "Step 1", description: "Tell us more about your business" },
  { title: "Step 2", description: "Add your contacts " },
  {
    title: "Step 3",
    description: "Add your Bank details and secure your accounts "
  },
  { title: "Step 4", description: "Owner Information" }
];

export const FirebaseCollection = {
  USERS: "USERS",
  LOGS: "LOGS",
  WALLET: "WALLET",
  ORDERS: "ORDERS",
  TRANSACTIONS: "TRANSACTIONS",
  FAQS: "FAQS"
};

export const LOGSLIST = [
  {
    title: "Facebook",
    type: "FACEBOOK",
    logo: Fb,
    description:
      "Lorem ipsum dolor sit amet consecte tur adipiscing elit olme do semperdalaracc lacus vel facilisis volutpat est velitolm."
  },
  {
    title: "Instagram",
    type: "INSTAGRAM",
    logo: Ig,
    description:
      "Lorem ipsum dolor sit amet consecte tur adipiscing elit olme do semperdalaracc lacus vel facilisis volutpat est velitolm."
  },
  {
    title: "Tiktok",
    type: "TIKTOK",
    logo: Tiktok,
    description:
      "Lorem ipsum dolor sit amet consecte tur adipiscing elit olme do semperdalaracc lacus vel facilisis volutpat est velitolm."
  },
  {
    title: "LinkedIn",
    type: "LINKEDIN",
    logo: LinkedIn,
    description:
      "Lorem ipsum dolor sit amet consecte tur adipiscing elit olme do semperdalaracc lacus vel facilisis volutpat est velitolm."
  }
];

export const LOGS_STATUS = [
  { title: "Available", type: "AVAILABLE" },
  { title: "Not Available", type: "NOT-AVAILABLE" }
];

export const LOGS_STATUS_OBJECT = {
  AVAILABLE: "AVAILABLE",
  UNAVAILABLE: "UNAVAILABLE"
};

export const LOGSTYPE = {
  IG: "INSTAGRAM",
  FB: "FACEBOOK",
  TIKTOK: "TIKTOK",
  LINKEDIN: "LINKEDIN",
  X: "X",
  SNAPCHAT: "SNAPCHAT"
};

export const TRANSACTION_TYPE = {
  CREDIT: "CREDIT",
  DEBIT: "DEBIT"
};

export const TRANSACTION_CATEGORY = {
  "TOP-UP": "TOP-UP",
  WITHDRAWAL: "WITHDRAWAL",
  PURCHASE: "PURCHASE"
};

export const TRANSACTION_STATUS = {
  SUCCESSFUL: "SUCCESSFUL",
  PENDING: "PENDING",
  FAILED: "FAILED"
};

export const EMIAL_TEMPLATE_ID = {
  CONTACT_US: "template_nlv1mej",
  ORDER: "template_wug6iuv"
};

export const PAYMENT_GATEWAY = {
  FLUTTERWAVE: "FLUTTERWAVE",
  KORAPAY: "KORAPAY",
  ETEGRAM: "ETEGRAM",
  SQUAD: "SQUAD",
  ALATPAY: "ALATPAY",
  SAFE_HAVEN: "SAFE_HAVEN",
  MANUAL: "MANUAL"
};

export const EMPTY_ARRAY = [{}, {}, {}, {}];
